@import '../base/variables';

$tooltipGreyBorder: #e2e8ea;

div.ui-tooltip.tooltip-container {
  position: relative;

  &:hover div.tooltip {
    visibility: visible;
    opacity: 1;

    @media #{$mobile} {
      visibility: hidden;
      opacity: 0;
    }
  }
}

div.ui-tooltip div.tooltip {
  z-index: 5;
  position: absolute;
  top: -3.5rem;
  left: -0.5rem;
  width: max-content;
  padding: 0.8rem;
  background: $white;
  border: 1px solid $tooltipGreyBorder;
  border-radius: 0.4rem;
  box-shadow: 0 7px 8px -2px rgba(0, 0, 0, 0.25);
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

div.ui-tooltip div.tooltip::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 1.3rem;
  width: 0;
  height: 0;
  border: 13px solid transparent;
  border-top-color: $white;
  border-bottom: 0;
  border-right: 0;
  margin-left: -6.5px;
  margin-bottom: -13px;
  z-index: 5;
}
