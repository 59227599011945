@import '../base/variables';

.ui-payment-record {
  display: flex;
  padding: 1rem 0;
  border-top: 1px dotted $secondary2;

  .date-time,
  .price-and-status {
    flex: 1;
    width: 100%;
  }

  .date-time {
    text-align: left;
  }

  .price-and-status,
  .price-and-status h3 {
    text-align: right;
  }

  .price-and-status {
    .status {
      background-color: $secondary2;
      padding: 0.313rem 1.375rem 0.5rem;
      border-radius: 0.625rem;
      margin-top: 0.313rem;
      min-width: 5.625rem;
      text-align: center;
      display: inline-block;
    }
  }

  &.reserve {
    .price-and-status {
      .status {
        color: $white;
        background-color: $black;
        text-transform: uppercase;
      }
    }
  }

  &.reserved {
    //not sure of styling here yet
  }

  &.paid {
    .price-and-status {
      p.status {
        color: $text4;
      }
    }
  }

  &.deleted {
    p:not(.status),
    h3 {
      opacity: 0.4;
    }

    .price-and-status {
      p.status {
        color: $text3;
      }
    }
  }
}
