@import '../base/variables';

div.ui-date-progress-bar {
  width: 100%;
}

div.ui-date-progress-bar ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0.313rem 0;
}

div.ui-date-progress-bar ul li {
  cursor: pointer;
  list-style: none;
  display: flex;
  flex: 1;
  position: relative;
  z-index: 1;
  height: 2.75rem;
}
//Date Progress Bar
div.ui-date-progress-bar ul li p {
  display: block;
  position: absolute;
  bottom: 0;
  width: 200%;
  left: -50%;
}

div.ui-date-progress-bar ul li::before {
  content: '';
  width: 0.8rem;
  height: 0.8rem;
  margin: 0.25rem auto 0 auto;
  border-radius: 50%;
  border: 2px solid $secondary3;
  background-color: $secondary2;
  box-shadow: 0 0 0 6px #fff;
}

div.ui-date-progress-bar ul li.current::before,
div.ui-date-progress-bar ul li.past::before {
  border: 2px solid $secondary4;
  background-color: $text4;
}

div.ui-date-progress-bar ul li.current::before {
  width: 1.25rem;
  height: 1.25rem;
  margin: 0 auto;
}

div.ui-date-progress-bar ul li::after {
  content: '';
  width: 100%;
  position: absolute;
  top: 0;
  height: calc(0.85rem - 1px);
  left: 0;
  border-bottom: 1px dotted $secondary2;
  z-index: -1;
  margin: 0;
}

div.ui-date-progress-bar ul li:first-child::after {
  left: 50%;
}

div.ui-date-progress-bar ul li:last-child::after {
  width: 50%;
}

div.ui-date-progress-bar ul li.first::after,
div.ui-date-progress-bar ul li.first.checked::after,
div.ui-date-progress-bar ul li.first.active::after {
  border-bottom: none;
}

div.ui-date-progress-bar div.description {
  width: 100%;
  display: flex;
}

div.ui-date-progress-bar div.description b.small {
  flex: 1;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
