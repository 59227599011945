@import '../base/variables';

ul {
  padding: 0 0 0 1.2em;
}

ul li {
  font: $bodyCopy2;
  color: $black;
  list-style: none;
}

ul li::before {
  content: '\2022';
  color: $secondary4;
  font-weight: bold;
  font-size: 1rem;
  display: inline-block;
  width: 1.2em;
  margin: 0 0 0 -1em;
}

// new list with bigger dots
.list {
  padding-left: 50px;
  li {
    position: relative;
    margin-bottom: 25px;
    font: $bodyCopy1;
    color: $text1;
    b,
    strong {
      display: block;
    }

    &:before {
      content: '';
      position: absolute;
      left: -34px;
      top: calc(50% - 13px);
      width: 25px;
      height: 25px;
      border-radius: 50%;
      border: 1px solid $bodyCopy3Color;
    }

    &:after {
      content: '';
      position: absolute;
      left: -44px;
      top: calc(50% - 7px);
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: $secondary4;
    }
  }

  &-top {
    li {
      &:before {
        top: -4px;
      }
      &:after {
        top: 2px;
      }
    }
  }
}

// new list with vertical line
.list-line {
  text-align: left;
  position: relative;
  padding-left: 25px;

  &::before {
    content: '';
    position: absolute;
    left: 5px;
    top: 6px;
    width: 1px;
    height: calc(100% - 12px);
    background-color: #979797;
  }

  li {
    position: relative;
    font: $bodyCopy1;
    color: $text1;

    &:before {
      content: '';
      position: absolute;
      left: -8px;
      top: 4px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $secondary4;
    }
  }
}
