@import '../base/variables';

$inputBorder: 1px solid $secondary2;

$inputFont: $bodyCopy1;
$inputFontColor: $bodyCopy1Color;
$inputFontErrorColor: $text3;
$inputCaretColor: $text4;

/* -------------------
    Labeled Input
-------------------- */
div.labeled-input {
  display: flex;
  position: relative;
  min-height: 3.125rem;
  width: calc(100% - 2px);
  border-radius: 0.625rem;
  border: $inputBorder !important;
  padding: 0 0.8rem;
  margin: 1rem auto 0 auto;
  box-sizing: border-box;
}

/* alignment */

div.labeled-input.aligned-right {
  margin: 1rem 0 0 auto;
}

div.labeled-input.aligned-left {
  margin: 1rem auto 0 0;
}

/* -------------------
    Labeled Title
-------------------- */
div.labeled-input > p {
  position: absolute;
  top: -0.8em;
  margin-left: -0.8em;
  font: $bodyCopy3;
  color: $bodyCopy3Color;
  width: fit-content;
  padding: 0 0.4rem !important;
  background: $white !important;
  opacity: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  animation: $fadeInAnimation;
}

div.labeled-input > p.visible {
  opacity: 1;
}

/* -------------------
    Toggle Password Visibility
-------------------- */
div.labeled-input > ui-icon.toggle-password-visibility {
  position: absolute;
  right: 0.8rem;
  top: 0.85rem;
  display: block;
  cursor: pointer;
}

/* -------------------
    Labeled Input
-------------------- */
div.labeled-input > input {
  flex: 1;
  font: $inputFont;
  color: $inputFontColor;
  border: none !important;
  outline: none !important;
  caret-color: $inputCaretColor;
  text-align: left;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1rem;
}

div.labeled-input > input::placeholder {
  color: $text1;
}

/* -------------------
Labeled Input - Extra Label
-------------------- */
div.labeled-input > div.extra.label {
  position: absolute;
  align-self: flex-end;
  font: $h4;
  color: $white;
  text-align: center;
  vertical-align: middle;
  line-height: 1.15em;
  border-radius: 0.625rem;
  padding: 0.2em 1.1em;
  margin: 0.8em 0 0 1.5em;
  width: fit-content;
  background: $secondary1;
  animation: $fadeInAnimation;
}

/* -------------------
       Text area
-------------------- */
div.labeled-input.area {
  min-height: 6em;
}

div.labeled-input.area > textarea {
  flex: 1;
  font: $inputFont;
  color: $inputFontColor;
  border: none !important;
  outline: none !important;
  resize: none !important;
  caret-color: $inputCaretColor;
  text-align: left;
  box-shadow: none;
  margin: 0.8rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* -------------------
       required
-------------------- */
div.labeled-input.required span.required-star {
  position: absolute;
  right: 1rem;
  top: 1.25rem;
  color: $text2;
}

div.labeled-input.error.required span.required-star {
  color: $text3;
}

span.required-star.required-star-dropdown {
  right: 2.5rem !important;
}

/* -------------------
       error
-------------------- */
div.labeled-input.error {
  border-color: $inputFontErrorColor !important;

  p.small.error {
    font-size: 0.625rem !important;
  }
}

/* -------------------
       Dropdown
-------------------- */
div.dropdown-wrapper {
  position: relative;
  opacity: 1;
}

div.dropdown-wrapper.hidden {
  opacity: 0;
}

div.dropdown-menu {
  width: 100%;
  position: absolute;
  overflow-y: auto;
  background: $white;
  border-radius: 0.625rem;
  box-shadow: 0 1px 9px 2px rgba(0, 0, 0, 0.1);
  z-index: 6;
}

div.dropdown-menu div.dropdown-item {
  cursor: pointer;
  height: 3em;
  width: calc(100% - 1.6rem);
  display: flex;
  align-items: center;
  justify-items: flex-start;
  text-align: left;
  margin: 0 0.8rem;
  border-bottom: 1px dotted $secondary2;
}

div.dropdown-menu div.button.dropdown-item {
  height: 2em;
}

div.dropdown-menu div.dropdown-item:last-child {
  border: none;
}

div.dropdown-menu div.dropdown-item p {
  width: 100%;
  font: $bodyCopy1;
  color: $bodyCopy1Color;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.dropdown-menu div.button.dropdown-item p {
  font: $bodyCopy2;
}

div.labeled-input.dropdown > input.filled {
  background: none !important;
}

/* -------------------
  Multiple Dropdown
-------------------- */
div.labeled-input > div.multiple-dropdown {
  width: fit-content;
  font: $inputFont;
  color: $inputFontColor;
  flex: 1 0 auto;
  border: none !important;
  outline: none !important;
  margin: 0.8rem 0 0.8rem 0.8rem;
  caret-color: $inputCaretColor;
  text-align: left;
  box-shadow: none;
  align-items: center;
  display: flex;
  flex-flow: row wrap;
  position: relative;
  padding-right: 1.6rem;
}

div.labeled-input > div.multiple-dropdown > span.value {
  font: $bodyCopy2;
  color: $text1;
  background: $secondary2;
  padding: 0.3em 1.8em 0.3em 0.4em;
  margin: 0 0.2em 0.2em 0;
  border-radius: 0.35em;
  position: relative;
}

div.labeled-input > div.multiple-dropdown > span.value ui-icon.cross {
  position: absolute;
  top: 0.3em;
  bottom: 0.3em;
  right: 0.5em;
}

div.labeled-input > div.loader {
  position: absolute;
  height: 1rem;
  width: 1rem;
  right: 1rem;
  top: 1rem;
}

div.labeled-input > div.multiple-dropdown > .no-value-placeholder {
  color: $bodyCopy3Color;
  margin-left: -0.8rem;
  margin-right: 0.8rem;
}

/* -------------------
       Label
-------------------- */
div.labeled-input-label {
  text-align: left;
  line-height: 1;

  i.bold {
    font-weight: bold;
  }
}

div.labeled-input p.visible {
  background: transparent !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 0.4em);
  box-sizing: border-box;

  span.label-text {
    background: white;
    padding: 0 0.4em;
  }

  ui-icon.info-tooltip {
    margin: 0 0.4em;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
  }
}

i.labeled-input-tooltip {
  position: absolute;
  background-color: $black;
  width: 50vw;
  text-align: left;
  color: $white;
  padding: 0.6rem;
  border-radius: 0.2rem;
  z-index: 100;
  right: 0;
  top: 1em;
  cursor: pointer;
}

/* -------------------
    Color Picker
-------------------- */
div.labeled-input.color {
  input[type='color'] {
    width: 2rem;
    height: 2rem;
    background-color: transparent;
    border: 0;
  }

  input[type='color' i] {
    -webkit-appearance: square-button;
    width: 5rem;
    height: 2rem;
    background-color: transparent;
    border-radius: 50%;
    cursor: default;
    border: none;
    margin-right: 0.5rem;
  }
}

div.labeled-input.color input.read-only {
  pointer-events: none !important;
}

/* -------------------
       Disabled
-------------------- */
div.labeled-input.disabled {
  cursor: default !important;
  opacity: 0.6 !important;
}

div.labeled-input.disabled > * {
  pointer-events: none !important;
}

div.labeled-input.aligned-center input {
  text-align: center;
}

div.labeled-input-label.aligned-center {
  text-align: center;
}
