@import '../base/variables';

ui-chat-content {
  flex: 1;
  position: relative;
}

ui-chat-input {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 5px 1px rgba(0, 0, 0, 0.15);
}

/* -------------------
        Message
-------------------- */
div.ui-chat-message {
  width: calc(14.3rem - 1.8rem);
  padding: 0.9rem;
  border-radius: 0.625rem;
  text-align: left;

  p.small,
  p.small.light {
    font-size: 0.875rem;
    line-height: 1.188rem;
  }

  p.small.light.date {
    font-size: 0.625rem;
  }

  p.word-break {
    word-break: break-word;
  }
}

div.ui-chat-end-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 1.5rem 0;

  span.text {
    margin: 0 1.5rem;

    p.small {
      color: $secondary2;
    }
  }

  ui-divider {
    flex: 1;
  }
}

img.ui-chat-message-img {
  width: 100%;
  object-fit: contain;
}

div.ui-chat-message-date {
  width: 14.3rem;
  font-size: 0.625rem;
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

// OUTGOING
div.ui-chat-message.outgoing {
  border-bottom-right-radius: 0;
  background: $secondary4;
  margin-left: auto;
}

div.ui-chat-message-date.outgoing {
  text-align: right;
  margin-left: auto;
}

// INCOMING
div.ui-chat-message.incoming {
  border-bottom-left-radius: 0;
  background: $secondary3;
  margin-right: auto;
}

div.ui-chat-message-date.incoming {
  text-align: left;
  margin-right: auto;
}

// NO BACKGROUND
div.ui-chat-message.no-background {
  background: $white !important;
}

/* -------------------
        Chat
-------------------- */
div.ui-chat-input {
  height: 3.125rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0 0.75rem;
  background-color: $white;
}

div.ui-chat-input-sticky {
  height: 3.125rem;
  width: 100%;
  position: fixed;
  bottom: 3.5rem;
  left: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  background-color: $white;
  box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.16);
}

div.ui-chat-input input {
  flex: 1;
  height: 1.875rem;
  font: $bodyCopy1;
  color: $bodyCopy1Color;
  padding: 0 0.75rem;
  margin: 0 0.75rem 0 0.75rem;
  border: 1px solid $secondary2;
  border-radius: 0.938rem;
  outline: none !important;
  caret-color: $secondary4;
  text-align: left;
  box-shadow: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.upload-chat-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

/* -------------------
        Panel
-------------------- */

ui-chat-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid $secondary3;
  border-radius: 0.5rem;
  position: relative;
}

ui-chat-panel div.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.25rem;
  overflow: hidden;

  h3 {
    flex: 1;
  }
}

ui-chat-panel section.content {
  height: calc(100% - 3.188rem);
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  top: 3.188rem;
  right: 0;
  left: 0;
  border-top: 1px solid $secondary3;
  overflow-y: auto;
  overflow-x: hidden;
}

ui-chat-panel section.content.collapsed {
  display: none;
}

div.ui-chat-item {
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1.25rem;

  b {
    flex: 1;
  }
}

div.ui-chat-message-inf-payment {
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
  border: 1px dotted $secondary3;
  border-radius: 0.625rem;
  padding: 0.75rem 1rem;
  position: relative;
  box-sizing: border-box;

  &.disabled {
    opacity: 0.2;
  }
}

div.date-title {
  width: 5rem;
  height: 1rem;
  margin: 0 auto 1.375rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: $black;
  border-radius: 0.313rem;

  p.small {
    height: auto;
    color: $white;
    font-size: 0.663rem;
  }
}

div.new-messages-indicator {
  pointer-events: none;
  position: sticky;
  bottom: -24px;
  left: 0;
  right: 0;
  border-bottom: 2px solid $secondary4;
  z-index: 1;

  div.inner {
    width: 1.875rem;
    border-top-right-radius: 0.7rem;
    border-top-left-radius: 0.7rem;
    background: $secondary4;
    padding: 0.15rem 1.5rem;

    p.small {
      color: $white;
    }
  }
}
