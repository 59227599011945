@import '../base/variables';

ui-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

ui-icon * {
  pointer-events: none;
}

ui-common-variant-image *,
img,
ui-icon img {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

ui-icon img.animation-pulsate {
  animation: pulsate 1.5s infinite;
}

ui-icon img.animation-rotate {
  animation: rotate 1.5s infinite;
}

ui-icon img.animation-rotate.animation-pulsate {
  animation: rotatePulsate 1.5s infinite;
}

div.loading-icon-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;

  div.small-task-spinner,
  div.small-task-image-spinner,
  div.small-task-svg-spinner {
    position: absolute;
    margin: auto 0;
    top: 100%;
    bottom: 100%;
  }
}

div.ui-image-preview-wrapper {
  margin: auto;

  div.container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
    }
  }
}

div.ui-image-preview-wrapper div.container.square {
  padding-top: 100%;
}

div.ui-upload-image {
  cursor: pointer;

  div.with-text {
    margin: 7rem 0;
  }
}

@keyframes pulsate {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes rotatePulsate {
  0% {
    transform: scale(1) rotate(0deg);
  }

  50% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
