@import '../base/variables';

div.ui-step-progress-bar {
  width: 100%;

  li::before {
    display: none !important;
  }
}

div.ui-step-progress-bar ul {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0;
  margin: 0.313rem 0;
}

div.ui-step-progress-bar ul li {
  cursor: pointer;
  list-style: none;
  display: flex;
  flex: 1;
  position: relative;
  z-index: 1;
  align-items: flex-start;
}

div.ui-step-progress-bar ul li::after {
  content: '';
  width: 85%;
  position: absolute;
  top: 0.6rem;
  left: -43%;
  border-bottom: 1px dotted $secondary2;
  z-index: -1;
}

div.ui-step-progress-bar ul li.first::after,
div.ui-step-progress-bar ul li.first.checked::after,
div.ui-step-progress-bar ul li.first.pending::after,
div.ui-step-progress-bar ul li.first.cancelled::after,
div.ui-step-progress-bar ul li.first.active::after {
  border-bottom: none;
}

div.ui-step-progress-bar div.description {
  width: 100%;
  display: flex;
}

div.ui-step-progress-bar div.description b.small {
  flex: 1;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
* Vertical step progress bar
*/
div.ui-step-progress-bar.vertical ul {
  flex-direction: column;
}

div.ui-step-progress-bar.vertical ul li {
  margin-bottom: 1.563rem;
}

div.ui-step-progress-bar.vertical ul li::after {
  border-bottom: none;
  border-left: 1px dotted $secondary2;
  top: 1.4rem;
  left: 0.55rem;
  width: 1px;
  height: 100%;
}

div.ui-step-progress-bar.vertical ul li:last-child::after {
  content: none;
}

div.ui-step-progress-bar.vertical ul li div.description {
  flex: 1;
  text-align: left;
  padding-left: 1.125rem;
  flex-direction: column;
}

div.ui-step-progress-bar.vertical ul li div.description b {
  display: block;
  margin-top: 0.15rem;
  text-align: left;
}
