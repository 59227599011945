@import '../base/variables';

div.ui-skeleton-screen-wrapper {
  overflow: hidden;
}

div.ui-skeleton-screen {
  cursor: initial;
  pointer-events: none;
  background: linear-gradient(to right, #eee 8%, darken(#eee, 5) 18%, #eee 33%);
  background-size: 200% 50px;
  animation: preloadAnimation 2s infinite;
  position: relative;
}

@keyframes preloadAnimation {
  from {
    background-position: -280px 0;
  }

  to {
    background-position: 300px 0;
  }
}

div.ui-skeleton-screen div.rect {
  height: 4rem;
  width: 4rem;
  border: 8px solid #fff;
  // Clear fix
  border-right: 100vw solid white;
  box-sizing: border-box;
}

div.ui-skeleton-screen div.line {
  height: 3rem;
  width: 100%;
  border: 8px solid #fff;
  box-sizing: border-box;
}

div.ui-skeleton-screen div.card {
  height: 9rem;
  width: 100%;
  border: 8px solid #fff;
  box-sizing: border-box;
}

div.ui-skeleton-screen div.list {
  height: 1rem;
  width: 100%;
  border-top: 4px solid #fff;
  border-bottom: 4px solid #fff;
  border-right: 8px solid #fff;
  border-left: 8px solid #fff;
  box-sizing: border-box;
}

div.rects-row {
  width: 100%;
  height: 5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  // Clear fix
  border-right: 100vw solid white;

  span.rect {
    width: 25%;
    border: 8px solid #fff;
    box-sizing: border-box;
  }
}

div.ui-skeleton-screen.compact * {
  div.card,
  div.line {
    width: 100%;
  }

  border: none !important;
}
