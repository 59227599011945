@import '../base/variables';

div.ui-select-location div.address-item {
  height: 3.5rem;
  cursor: pointer;
}

div.ui-select-location div.bottom-button {
  position: fixed;
  height: 4rem;
  width: 100%;
  bottom: 0;
  background: $white;
  cursor: pointer;
}
