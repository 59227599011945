@import '../base/variables';

/* -------------------
Global Button Settings
-------------------- */
button {
  cursor: pointer;
  font: $buttonFont;
  height: 1.875rem;
  display: inline-flex;
  align-items: center;
  color: $black;
  text-align: center;
  text-transform: uppercase !important;
  border-radius: 1.063rem;
  outline: none;
  transition: all 250ms;
  position: relative;
}

/* -------------------
        Content
-------------------- */
button.content > span.button-content {
  width: 100%;
  padding: 0 0.5rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* -------------------
    Default Button
-------------------- */
button.callToAction,
button {
  height: 1.875rem;
  background: $buttonDefaultBackground;
  border: $buttonDefaultBorder;
}

button > span.button-content {
  color: $buttonDefaultColor;
}

/* -------------------
   Selected Button
-------------------- */
button.selected.callToAction,
button.selected {
  background: $buttonSelectedBackground;
  border: $buttonSelectedBorder;
}

button.selected > span.button-content {
  color: $buttonSelectedColor;
}

button.selectedBlue.callToAction,
button.selectedBlue {
  background: $text2;
  border: $text2;
}

button.selectedBlue > span.button-content {
  color: $buttonSelectedColor;
}

button.selectedWhite.callToAction,
button.selectedWhite {
  background: $white;
  border: $white;
}

button.selectedWhite > span.button-content {
  color: $black;
}

/* -------------------
       disabled
-------------------- */
button.disabled.callToAction,
button.disabled {
  cursor: default;
  opacity: 0.6;
  background: $buttonDisabledBackground;
  color: $buttonDisabledColor;
  border: $buttonDisabledBorder;
}

/* -------------------
call to action (CTA)
-------------------- */
button.callToAction {
  min-width: 14.063rem;
  height: 2.5rem;
  font: $buttonCTAFont;
  border-radius: 1.563rem;
  margin: 0 auto;
}

button.callToAction.padded,
button.callToAction.selected.padded {
  margin: 0 auto 1em !important;
}

button.callToAction > span.button-content {
  padding: 0 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* -------------------
    Action Button
-------------------- */
div.action-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: flex-start;
}

div.action-button > button {
  height: 3.3rem;
  width: 3.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary1;
  border: initial !important;
  border-radius: 1.2rem;
  padding: 0 !important;
}

div.action-button > button ui-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 1.2rem;
  overflow: hidden;
}

div.action-button > button img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 1.2rem;
  object-fit: cover;
}

div.action-button > div.button-description {
  width: 5.2em;
  font: $bodyCopy2;
  color: $black;
  text-align: center;
  white-space: normal;
}

/* -------------------
       Borderless
-------------------- */
button.borderless {
  border: none;
}

button.facebook {
  background-color: #4267b2;
  border-color: #6583c0;
}

button.facebook > span.button-content {
  color: $white;
  padding: 0 0.25rem;
  justify-content: left;
}

button.facebook > span.button-content ui-icon {
  width: 1.25rem;
  height: 1.25rem;
}

button.google {
  background-color: #db504b;
  border-color: #df6561;
}

button.google > span.button-content {
  color: $white;
  padding: 0 0.25rem;
  justify-content: left;
}

button.google > span.button-content ui-icon {
  width: 1.25rem;
  height: 1.25rem;
}

button.apple {
  background-color: #2c2c2e;
  border-color: #1c1c1e;
}

button.apple > span.button-content {
  color: $white;
  padding: 0 0.25rem;
  justify-content: left;
}

button.apple > span.button-content ui-icon {
  width: 1.25rem;
  height: 1.25rem;
}

button.launch-offer-banner-button {
  background-color: transparent;
  border-color: $white;
}

button.launch-offer-banner-button > span.button-content {
  color: $white;
  padding: 0 0.25rem;
  justify-content: left;
}

button.launch-offer-banner-button > span.button-content ui-icon {
  width: 1.25rem;
  height: 1.25rem;
}

/* -------------------
       fluid
-------------------- */
ui-button[fluid='true'] {
  width: 100%;

  &[padded='true'] {
    width: calc(100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide} - 2px);
  }
}

ui-button[fluid='true'] button.fluid {
  width: 100%;
}

ui-button[fluid='true'] button.fluid span.button-content {
  margin-left: auto;
  margin-right: auto;
}

button.fluid {
  width: 100%;
  margin: 0 auto 0 !important;
}

button.fluid.padded {
  width: calc(100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide} - 2px);
}

button.fluid span.button-content {
  margin-left: auto;
  margin-right: auto;
}

/* -------------------
       align
-------------------- */

ui-button[aligned='bottom'] {
  margin: auto auto 0 !important;
}

ui-button[aligned='bottom'] ~ ui-button[aligned='bottom'] {
  margin: 0 auto 1em !important;
}

ui-button[aligned='top'] {
  margin: 0 auto auto !important;
}

ui-button[aligned='top'] ~ ui-button[aligned='top'] {
  margin: 1em auto 0 !important;
}

/* -------------------
       compact
-------------------- */
button.compact {
  margin: 0;
}

/* -------------------
       Helpers
-------------------- */
button > * {
  pointer-events: none;
}

div.badge-label {
  position: absolute;
  right: -0.75rem;
  top: -0.75rem;
  background-color: $text2;
  padding: 0.25rem;
  border-radius: 0.75rem;
  min-width: 1rem;
}

div.badge-label > p {
  color: $white;
}
