@import '../base/variables';

div.ui.quantity.picker {
  width: 9.563rem;
  height: 1.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: $white;
  border: 1px solid $secondary2;
}

div.ui.quantity.picker.small {
  width: 8.4rem !important;
}

div.ui.quantity.picker input {
  font: $h3;
  width: calc(100% - 4.5em);
  text-align: center;
}

div.ui.quantity.picker input[type='number']::-webkit-inner-spin-button,
div.ui.quantity.picker input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

div.ui.quantity.picker input[type='number'] {
  -moz-appearance: textfield;
}

div.ui.quantity.picker span.plus,
div.ui.quantity.picker span.minus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.375rem;
  height: 1.375rem;
  background: #e5e5e5;

  a {
    font-size: 1.1rem;
    font-weight: 900;
  }
}

div.ui.quantity.picker span.plus {
  cursor: pointer;
  border-top-right-radius: 0.35rem;
  border-bottom-right-radius: 0.35rem;
  margin: 0 0.27em 0 auto;
}

div.ui.quantity.picker span.minus {
  cursor: pointer;
  border-top-left-radius: 0.35rem;
  border-bottom-left-radius: 0.35rem;
  margin: 0 auto 0 0.27em;
}
