@import '../base/variables';

.ui-chat-preview {
  padding-top: 1rem;
  text-align: left;

  .header-wrapper {
    margin-bottom: 0.563rem;

    h3,
    p {
      display: inline-block;
    }

    h3 {
      margin-right: 0.375rem;
    }
  }

  .message-wrapper {
    .message {
      background-color: $secondary3;
      padding: 0.5rem 0.938rem;
      margin-bottom: 0.313rem;
      width: 55%;
      border-radius: 0.625rem;
    }
  }
}
