@import '../base/variables';

ui-segment {
  display: flex;
  flex: 1;
}

/* -------------------
        Container
-------------------- */

div.ui-segment {
  flex: 1;
  display: flex;
  position: relative;
}

div.ui-segment div.segment-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-top-left-radius: $containerBorderRadius;
  border-top-right-radius: $containerBorderRadius;
  box-shadow: 0 -5px 12px -1px rgba(0, 0, 0, 0.15);
  background: $white;
  padding: $containerContentPadding;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  // no bottom: 0, because of segment bug on safari

  box-sizing: border-box;
}

/* -------------------
   Container helper
       classes
-------------------- */

div.ui-segment.compact div.segment-wrapper {
  padding: 1.5rem 0 $containerVerticalPadding 0 !important;
}

div.ui-segment.no-bottom-padding div.segment-wrapper {
  padding-bottom: 0 !important;
}

div.ui-segment.no-top-padding div.segment-wrapper {
  padding-top: 0 !important;
}

div.ui-segment.borderless div.segment-wrapper {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
}

div.ui-segment.left div.segment-wrapper {
  text-align: left;
}

div.ui-segment.right div.segment-wrapper {
  text-align: right;
}
