@import '../base/variables';

div.ui-switch-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
}

div.ui-switch-button div.switch-buttons {
  width: 320px;
  height: 2.4rem;
  overflow: hidden;
  position: relative;
}

div.ui-switch-button div.switch-buttons.full-width {
  width: 100%;
}

div.ui-switch-button div.switch-buttons span.first-tab,
div.ui-switch-button div.switch-buttons span.second-tab {
  border-radius: 1.25rem;
  border: 1px solid $secondary2;
  background: $white;

  p {
    text-transform: uppercase;
    font: $bodyCopy2;
    color: $black;
  }
}

div.ui-switch-button div.switch-buttons span.first-tab {
  width: 52%;
  position: absolute;
  top: 0;
  right: 35%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 0.625rem 0 0.625rem 2.5rem;
  text-align: left;
}

div.ui-switch-button div.switch-buttons span.second-tab {
  width: 52%;
  position: absolute;
  top: 0;
  left: 35%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0.625rem 2.5rem 0.625rem 0;
  text-align: right;
}

div.ui-switch-button div.switch-buttons span.first-tab.active,
div.ui-switch-button div.switch-buttons span.second-tab.active {
  width: auto;
  background: $black !important;
  border: 1px solid $black !important;
  border-radius: 1.25rem !important;
  z-index: 1;

  p {
    text-transform: uppercase;
    font: $bodyCopy2;
    color: $white;
  }
}

div.ui-switch-button div.switch-buttons span.first-tab.active {
  right: 52%;
  left: 0;
}

div.ui-switch-button div.switch-buttons span.second-tab.active {
  left: 45%;
  right: 0;
}

div.ui-switch-button.small div.switch-buttons {
  width: 3.5rem;
  height: 1.24rem;

  span.first-tab {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60%;
    right: 40%;
    padding: 0 0 0 0.35rem;
  }

  span.second-tab {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 60%;
    left: 40%;
    padding: 0 0.35rem 0 0;
  }

  span.first-tab.active {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 55%;
    right: 45%;
    padding: 0 0 0 0.35rem;
  }

  span.second-tab.active {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 55%;
    left: 45%;
    padding: 0 0.35rem 0 0;
  }

  span.first-tab.active,
  span.second-tab.active,
  span.first-tab,
  span.second-tab {
    min-height: 1.24rem;
    box-sizing: border-box;

    p {
      font: normal normal normal 0.5rem 'Nunito Sans Bold', sans-serif;
    }
  }
}

div.ui-switch-button.medium div.switch-buttons {
  width: 10.5rem;
  height: 1.75rem;

  span.first-tab,
  span.second-tab {
    display: flex;
    align-items: center;
    width: 60%;

    p {
      color: $secondary2;
    }
  }

  span.first-tab {
    justify-content: flex-start;
    right: 40%;
    padding: 0 0 0 1.1rem;
  }

  span.second-tab {
    justify-content: flex-end;
    left: 40%;
    padding: 0 1.1rem 0 0;
  }

  span.first-tab.active,
  span.second-tab.active {
    justify-content: center;
    width: 55%;
    padding: 0;
    background: $primary1 !important;
    border-color: $primary1 !important;

    p {
      color: $white;
    }
  }

  span.first-tab.active {
    right: 45%;
  }

  span.second-tab.active {
    left: 45%;
  }

  span.first-tab.active,
  span.second-tab.active,
  span.first-tab,
  span.second-tab {
    min-height: 1.75rem;
    box-sizing: border-box;
  }
}
