@import '../base/variables';

div.ui-divider {
  width: 100%;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

div.ui-divider.compact {
  height: calc(1.5rem / 2) !important;
}

div.ui-divider.large {
  height: calc(1.5rem * 2) !important;
}

div.ui-divider div.line {
  width: 100%;
  height: 1px;
}

div.ui-divider.full-width div.line,
div.ui-divider.full-width div.dotted {
  margin: 0 - 1.25rem;
  width: 100vw;
}

div.ui-divider div.line.dotted {
  border-top: 1px dotted $dividerBorderColor;
}

div.ui-divider div.line.solid {
  border-bottom: 1px solid $dividerBorderColor;
}

div.ui-divider.padded {
  width: calc(100% - #{$pageContentPaddingOnSide} - #{$pageContentPaddingOnSide});
  padding: 0 $pageContentPaddingOnSide;
}

/* -------------------
      VERTICAL
-------------------- */
span.ui-divider.vertical {
  width: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
}

span.ui-divider.vertical.compact {
  width: calc(1.5rem / 2) !important;
}

span.ui-divider.vertical div.line {
  width: 1px;
  height: 100%;
  min-height: 1.5em;
  margin: auto;
}

span.ui-divider.vertical div.line.dotted {
  border-right: 1px dotted $dividerBorderColor;
}

span.ui-divider.vertical div.line.solid {
  border-right: 1px solid $dividerBorderColor;
}
