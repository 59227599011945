@import '../base/variables';

header.header-container {
  height: $applicationHeaderHeight;
  display: flex;
  flex-direction: row;
  align-items: center;
  // margin: 0 $pageContentPaddingOnSide;
  box-sizing: border-box;
  border-radius: 0 0 1.5rem 1.5rem;
  padding-right: 1.5rem;
}

header.header-container.limited {
  height: $applicationHeaderHeight;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0 $pageContentPaddingOnSide;
  box-sizing: border-box;
}

/* -------------------
       ICON
-------------------- */

header.header-container span.header-icon {
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
}

header.header-container span.header-icon-my-cart {
  cursor: pointer;
  display: flex;
  height: 100%;
  align-items: center;
}

header.header-container span.header-icon > i.icon.merchant-back-arrow-inverted,
header.header-container span.header-icon > i.icon.back-arrow {
  cursor: pointer;
  width: 0.5rem;
}

header.header-container span.header-icon-mycart > ui-icon[icon='my-cart'] {
  cursor: pointer;
  width: 0.5rem;
}

img.avo-logo-by-nedbank {
  cursor: pointer;
  height: 3rem;
}

/* -------------------
       INNER
-------------------- */
header.header-container span.header-inner {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  position: relative;
}

header.header-container span.header-inner span.chained-header-title {
  flex: 1;

  h3 {
    cursor: pointer;
  }

  h3.active {
    cursor: initial;
    color: $primary1;
  }

  ui-icon.next {
    padding: 0 0.5rem;
  }
}

/* -------------------
        Title
-------------------- */
header.header-container span.header-inner span.header-title {
  flex: 1;
  min-width: 0;
  padding: 0 0 0 0.5rem;

  h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $bodyCopy1Color;
    text-transform: uppercase;
  }
}

/* -------------------
     tab-wrapper
-------------------- */
header.header-container span.header-inner span.header-tab-wrapper {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  white-space: nowrap;
  z-index: 2;
}

.background-stars {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;

  &.right {
    right: 0;
    left: auto;
  }

  @media #{$mobile} {
    display: none;
  }
}
