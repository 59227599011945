@import '../base/variables';

/* -------------------
  Push notifications
-------------------- */

div.ui-push-notification {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 1.25rem;
  left: 0;
  right: 0;
  min-width: 16rem;
  max-width: 90%;
  min-height: 1.563rem;
  border-radius: 0.75rem;
  margin: 0 auto;
  padding: 0.313rem 0;
  font: $bodyCopy2;
  color: $white;
  text-align: center;
  z-index: 1999999999999;
  background: $secondary5;
  box-shadow: 0 1px 12px 2px rgba(0, 0, 0, 0.23);
  box-sizing: border-box;
}
