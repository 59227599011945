@import 'variables';

/* -------------------
     html & body
-------------------- */
html,
body {
  font-size: $fontSize;
  height: 100%;
  min-height: 100%;
}

html a:hover,
html a:focus {
  text-decoration: none;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  // overflow: hidden;
  background: $pageBackground;
  font-family: $pageFont;
  color: $textColor;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -------------------
    main settings
-------------------- */
main {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

/* These are top level container components that need flexbox to render correctly */
main .flex-enabled + * {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

/* -------------------
  Removed outlines
-------------------- */
img:focus,
input:focus,
textarea:focus,
button:focus,
div:focus,
span:focus {
  outline: 0;
}

img:active,
input:active,
textarea:active,
button:active,
div:active,
span:active {
  outline: 0;
}
