@import 'assets/styles/main.scss';

/* -------------------
     Font faces
-------------------- */
@font-face {
  font-family: 'Nunito Sans Regular';
  src: url('/assets/fonts/NunitoSans-Regular.woff2') format('woff2'),
    url('/assets/fonts/NunitoSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans Italic';
  src: url('/assets/fonts/NunitoSans-Italic.woff2') format('woff2'),
    url('/assets/fonts/NunitoSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Nunito Sans Bold';
  src: url('/assets/fonts/NunitoSans-Bold.woff2') format('woff2'),
    url('/assets/fonts/NunitoSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

/* -------------------
       Headers
-------------------- */
h1 {
  font: $h1;
  color: $h1Color;
  margin: $headersMargin;
}

h2 {
  font: $h2;
  color: $h2Color;
  margin: $headersMargin;
}

h3 {
  font: $h3;
  color: $h3Color;
  margin: $headersMargin;
}

h4 {
  font: $h4;
  color: $h4Color;
  margin: $headersMargin;
}

/* -------------------
       Links
-------------------- */
a {
  cursor: pointer;
  font: $hyperlink;
  color: $hyperlinkColor;
  margin: $textsMargin;
}

a.small {
  cursor: pointer;
  font: $smallerHyperlink;
  color: $smallerHyperlinkColor;
  margin: $textsMargin;
}

a:link {
  text-decoration: none;
}

/* -------------------
       Text
-------------------- */
p {
  font: $bodyCopy1;
  color: $bodyCopy1Color;
  margin: $textsMargin;
}

p.small {
  font: $bodyCopy2;
  color: $bodyCopy2Color;
  margin: $textsMargin;
}

p.error {
  color: $text3;
}

p.success {
  color: $text4;
}

p.light {
  color: $bodyCopy3Color;
}

b {
  font: $bodyCopy4;
  color: $bodyCopy4Color;
  margin: $textsMargin;
}

b.small {
  font: $bodyCopy5;
  color: $bodyCopy4Color;
  margin: $textsMargin;
}

b.completed {
  font: $bodyCopy5;
  color: $bodyCopy5Color;
  margin: $textsMargin;
}

b.large {
  font: $bodyCopy6;
  color: $bodyCopy6Color;
  margin: $textsMargin;
}

b.light {
  color: $text1;
}

b.error {
  color: $text3;
}

i {
  display: block;
  font: $subtext;
  color: $subtextColor;
  margin: $textsMargin;
}

i.error {
  display: block;
  font: $subtext;
  color: $text3;
  margin: $textsMargin;
}
