@import '../base/variables';

div.ui-pin {
  width: 20rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em 0 1em;
  margin: 0 auto;
  background: transparent;
  box-sizing: border-box;
}

/* -------------------
      Pin Input
-------------------- */
div.ui-pin span.pin-item input {
  width: 3.125rem;
  height: 3.125rem;
  font: $h1;
  color: $black;
  caret-color: $text4;
  text-align: center;
  margin: auto auto;
  background: transparent;
  padding: 0;
  border: 0;
  transition: all 0.3s ease-out;
}

div.ui-pin span.pin-item input[type='number']::-webkit-inner-spin-button,
div.ui-pin span.pin-item input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

div.ui-pin span.pin-item input[type='number'] {
  -moz-appearance: textfield;
}

/* -------------------
   Pin Input Wrapper
-------------------- */
div.ui-pin span.pin-item {
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  flex-direction: column;
  font: $h1;
  text-align: center;
  border: 1px solid $secondary2;
  border-radius: 0.37em;
  background: $white;
  transition: all 0.3s ease-out;
}

/* ----------------------
Filled Pin Input Wrapper
----------------------- */
div.ui-pin span.pin-item.filled {
  background: transparent;
  border: 1px solid $secondary2;
  transition: all 0.3s ease-out;
}

/* ----------------------
     Filled Pin Input
----------------------- */
div.ui-pin span.pin-item.filled input {
  width: 3.125rem;
  height: 3.125rem;
  font: $h1;
  color: $black;
  caret-color: $text4;
  margin: 0.35em auto 0;

  &:focus {
    color: $black;
  }
}

div.ui-pin span.pin-item.filled.digits input {
  font-size: 1.45em;
  margin: 0 !important;
}

/* ----------------------
      Incorrect Pin
----------------------- */
div.ui-pin.errored span.pin-item {
  background: $white;
  border: 1px solid $text3;
}

div.ui-pin.errored span.pin-item input {
  color: $black;
}
