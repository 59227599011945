@import '../base/variables';

div.ui-item {
  display: block;
  width: 100%;

  &.medium {
    max-width: 16.75rem;
  }

  &.small {
    max-width: 12rem;
  }

  div.item-wrapper {
    position: relative;
  }

  &.has-shadow {
    div.inner {
      box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.35);
      border: none;
    }
  }

  &.border-solid {
    div.inner {
      border: 1px solid $secondary2;
    }
  }

  &.border-dotted {
    div.inner {
      border: 1px dotted $secondary2;
    }
  }

  &.round-corners {
    div.inner {
      border-radius: 0.79em;
    }
  }

  &.no-background {
    div.inner {
      background: transparent;
    }
  }

  &.has-checkbox,
  &.has-checkbox.compact {
    .item-wrapper {
      .inner {
        padding-left: 1.5rem !important;
      }
    }
  }

  &.has-favourite,
  &.has-share,
  &.has-chat,
  &.has-reviews,
  &.has-close {
    div.inner {
      span.description {
        h4 {
          padding-right: 1rem;
        }
      }
    }
  }

  &.has-favourite.has-share,
  &.has-favourite.has-chat,
  &.has-share.has-reviews,
  &.has-favourite.has-reviews,
  &.has-chat.has-reviews {
    div.inner {
      span.description {
        h4 {
          padding-right: 2.5rem;
        }
      }
    }
  }

  &.has-favourite.has-share.has-chat {
    div.inner {
      span.description {
        h4 {
          padding-right: 2.5rem;
        }
      }
    }
  }

  &.icon-right {
    div.inner {
      div.avatar-wrapper {
        order: 2;
        margin: 0 0 0 0.938rem;
      }
    }

    &.compact {
      .inner {
        padding-left: 1rem;
      }
    }
  }

  &.dark-grad {
    &.has-close {
      .item-wrapper {
        ui-icon[icon='close'] {
          color: $white;
        }
      }
    }

    &:not(.has-checkbox) {
      padding: 0;
    }

    .item-wrapper {
      .inner {
        color: $white;

        h4,
        p {
          color: $white !important;
        }

        h4 {
          font-size: 0.8rem;
        }

        p {
          white-space: normal !important;
        }
      }
    }

    &.mid-green {
      .item-wrapper {
        .inner {
          border-radius: 0.4rem;

          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#52b78a+0,307255+100 */
          background: rgb(82, 183, 138);

          /* Old browsers */
          background: -moz-linear-gradient(top, rgba(82, 183, 138, 1) 0%, rgba(48, 114, 85, 1) 100%);

          /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(82, 183, 138, 1) 0%, rgba(48, 114, 85, 1) 100%);

          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(82, 183, 138, 1) 0%, rgba(48, 114, 85, 1) 100%);

          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:dximagetransform.microsoft.gradient(startColorstr='#52b78a', endColorstr='#307255', GradientType=0);

          /* IE6-9 */
        }
      }
    }

    &.dark-green {
      .item-wrapper {
        .inner {
          /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#00483c+0,005d4d+100 */
          background: rgb(0, 72, 60);

          /* Old browsers */
          background: -moz-linear-gradient(top, rgba(0, 72, 60, 1) 0%, rgba(0, 93, 77, 1) 100%);

          /* FF3.6-15 */
          background: -webkit-linear-gradient(top, rgba(0, 72, 60, 1) 0%, rgba(0, 93, 77, 1) 100%);

          /* Chrome10-25,Safari5.1-6 */
          background: linear-gradient(to bottom, rgba(0, 72, 60, 1) 0%, rgba(0, 93, 77, 1) 100%);

          /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
          filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00483c', endColorstr='#005d4d', GradientType=0);

          /* IE6-9 */
        }
      }
    }
  }

  ui-icon.checkbox,
  ui-icon[icon='cross'] {
    position: absolute;
    z-index: 1;
  }

  ui-icon.checkbox {
    height: fit-content;
    position: absolute;
    left: -0.7rem;
    top: 0;
    bottom: 0;
    margin: auto 0 !important;
    z-index: 1;
  }

  ui-icon[icon='cross'] {
    top: 0.7rem;
    right: 1rem;
    color: $white;
    display: block;
  }

  &.full-width {
    ui-icon[icon='cross'] {
      right: 0.625rem;
      display: block;
    }
  }

  div.inner {
    min-height: 1.75rem;
    cursor: pointer;
    background: $white;
    $innerHorizontalPadding: 0.938em;
    $innerVerticalPadding: 1.2em;

    height: calc(100% - #{$innerHorizontalPadding} - #{$innerHorizontalPadding});
    display: flex;
    padding: $innerHorizontalPadding $innerVerticalPadding;
    align-items: center;
    position: relative;
    overflow: hidden;

    &.has-header {
      flex-direction: column;
      padding: 0;

      div.description {
        padding: 1.25rem 0;
        width: 100%;
      }
    }

    div.avatar-wrapper {
      display: block;
      width: 3.125em;
      margin-right: 0.938em;
      position: relative;

      img.avatar {
        width: 3.125em;
        height: 3.125em;
        object-fit: cover;
        border-radius: 50%;
        justify-self: end;
      }

      i.icon {
        width: auto;
        height: auto;
      }
    }

    div.header {
      width: 100%;
      background-color: $secondary1;
      border-bottom: 1px dotted $secondary2;

      h4 {
        padding: 0 1.25rem;
        line-height: 2.313rem;
        text-align: left;
      }
    }

    &:not(.has-header) {
      div.description {
        width: 100%;
      }
    }

    div.description {
      text-align: left;
      justify-self: start;
      display: block;
      box-sizing: border-box;
      position: relative;

      div.title-row {
        display: flex;
        flex-direction: row;

        h4 {
          flex: 1;
        }

        .action-items {
          display: flex;
          align-items: center;
          text-align: right;

          ui-icon,
          a {
            display: inline-block;
            margin-left: 0.375rem;
          }
        }
      }
    }
  }

  &.compact {
    .item-wrapper {
      padding: 0 !important;

      .inner {
        padding: 0.5rem !important;
        width: auto;
      }
    }
  }
}
