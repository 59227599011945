@import '../base/variables';

wakanda-merchant-navigation,
wakanda-customer-navigation {
  z-index: 1401;
}

footer.navigation-container {
  width: 100vw;
  height: 3.438rem;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 0;
  background-color: $secondary3;
}

footer.navigation-container div.navigation-items {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-items: center;
  background-color: $secondary3;
  box-shadow: 0 -2px 5px 1px rgba(0, 0, 0, 0.15);

  /* -------------------
        Column settings
    -------------------- */
  &.selected {
    background-color: transparent;
    box-shadow: none;
  }

  /* -------------------
            Items
    -------------------- */
  wakanda-customer-navigation-item,
  wakanda-merchant-navigation-item {
    flex: 1;
  }

  wakanda-customer-navigation-item-icon,
  wakanda-merchant-navigation-item-icon {
    z-index: 1;
  }

  div.row {
    display: flex;
    height: 100%;
  }

  div.item.disabled {
    opacity: 0.25;
  }

  div.item {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $secondary3;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    transition: border-radius ease 700ms;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: scaleY(1);
      transform-origin: top center;
      background-color: $white;
      z-index: 0;
      opacity: 1;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      transition: transform 700ms, opacity 700ms, border-radius ease 700ms;
    }

    &.active::after {
      transform: scaleY(0);
      opacity: 0;
    }

    &.prev,
    &.prev::after {
      border-top-right-radius: 0.938rem;
    }

    &.next,
    &.next::after {
      border-top-left-radius: 0.938rem;
    }

    img.avatar {
      width: 1.375rem;
      height: 1.375rem;
      border-radius: 50%;
      object-fit: cover;
      text-align: center;
      background: $black;
    }

    span {
      z-index: 1;
    }

    b.content {
      font-size: 0.5rem;
      color: $black;
      text-transform: uppercase;
      line-height: 1.2rem;
      z-index: 1;
    }

    p.content {
      font-size: 0.5rem;
      color: $text1;
      text-transform: uppercase;
      line-height: 1.2rem;
      z-index: 1;
    }

    div.mark {
      width: 2.5em;
      height: 0.5em;
      position: absolute;
      bottom: 0;
      margin: 0 auto;
      border-top-left-radius: 2em;
      border-top-right-radius: 2em;
      background: $black;
      animation: $slideInUpAnimationKey 0.5s ease-out;
      z-index: 1;
    }

    div.indicator {
      width: 0.7rem;
      height: 0.7rem;
      position: absolute;
      bottom: 2.2rem;
      left: 34%;
      border-radius: 100%;
      background: $text4;
      z-index: 3;
    }
  }

  div.empty-item {
    width: 0.6rem;
    height: 100%;
    background-color: $white;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    transition: border-radius ease 700ms;

    &.first {
      border-top-right-radius: 0.938rem;
    }

    &.last {
      border-top-left-radius: 0.938rem;
    }
  }
}
