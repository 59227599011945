@import '../base/variables';

div.ui-toggle-wrapper.with-title {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  text-align: start;
  margin: 1em 0;

  p {
    width: 100%;
    font: $bodyCopy2;
    color: $text1;
  }
}

div.ui-toggle-wrapper.compact {
  margin: 0;
}

div.ui-toggle {
  width: 2.5rem;
  height: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.075rem;
  background: $secondary2;
  border-radius: 1.3em;
  transition: 0.3s all ease-in-out;
}

div.ui-toggle > div.toggle-control {
  position: absolute;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 50%;
  background: $white;
  left: 0.075rem;
  right: unset;
  transition: 0.3s all ease-in-out;
}

div.ui-toggle.active {
  background: $primary1;
  transition: 0.3s all ease-in-out;
}

div.ui-toggle.active > div.toggle-control {
  left: unset;
  right: 0.075rem;
  transition: 0.3s all ease-in-out;
}
