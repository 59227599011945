@import '../base/variables';

div.ui-horizontal-content {
  display: flex;
  align-items: stretch;
  flex-flow: row wrap;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-end;
  padding: 0 0.75rem;
}

div.ui-horizontal-content.products .ui-horizontal-content-item {
  width: calc(50% - 1.5rem) !important;
  margin: 0.75rem !important;
  box-sizing: border-box !important;
}

div.ui-horizontal-content.services .ui-horizontal-content-item {
  width: 20% !important;
  padding: 0.75rem !important;
  box-sizing: border-box !important;
}

div.ui-horizontal-content-product-item {
  width: auto;
  min-height: 12.5rem;
  border: 1px solid #ccc;
  border-radius: 0.625rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div.image-wrapper {
    height: 9.688rem;
    position: relative;
    display: flex;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }

    $discountArrowWidth: calc(#{$discountFlagWidth} / 2);
    $discountArrowHeight: calc(#{$discountFlagWidth} / 4);

    .discount-flag {
      position: absolute;
      top: 0;
      left: 0.75rem;
      display: block;
      width: $discountFlagWidth;

      .discount-flag-content {
        display: block;
        background-color: $secondary4;
        padding: 0.2rem;
        text-align: center;

        p,
        b {
          font-size: 0.6rem;
          color: $white;
        }
      }

      .discount-corners {
        position: relative;

        .discount-corner {
          width: 0;
          height: 0;
          border-style: solid;
          position: absolute;
          top: -1px;

          &.right {
            border-width: 0 $discountArrowWidth $discountArrowHeight 0;
            border-color: transparent $secondary4 transparent transparent;
            right: 0;
          }

          &.left {
            border-width: $discountArrowHeight $discountArrowWidth 0 0;
            border-color: $secondary4 transparent transparent transparent;
            left: 0;
          }
        }
      }
    }
  }

  div.description {
    width: 100%;
    padding: 0 0.5rem;
    margin: 0 0 auto;
    box-sizing: border-box;

    div.name-wrapper {
      span.name {
        flex: 1;
        min-width: 0; /* or some value */

        p.small {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      span.icon {
        white-space: nowrap;
      }
    }
  }
}

div.ui-horizontal-content-service-item {
  img.icon {
    width: 3rem;
    height: 3rem;
    background-color: $secondary2;
    object-fit: cover;
    border-radius: 1.1rem;
  }

  p.small,
  b.small {
    margin-top: 0.7rem;
    text-align: center;
  }
}

div.ui-horizontal-content-prepaid-item {
  width: 10rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 1rem 0.5rem;
  text-align: center;
  border-radius: 0.938rem;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
  box-sizing: border-box;

  img {
    width: 2.5rem;
    height: auto;
    object-fit: contain;
    margin: 0 auto;
    background: $secondary3;
    border-radius: 0.25rem;
  }
}
