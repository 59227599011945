@import '../base/variables';

div.ui-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

div.ui-checkbox .checkbox-icon-wrapper {
  cursor: pointer;
}

div.ui-checkbox.disabled {
  opacity: 0.333;
}

/* -------------------
    label settings
-------------------- */

span.ui-checkbox-label {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  margin: 0 0 0 0.7rem;

  p {
    font: $bodyCopy1;
    color: $bodyCopy1Color;
    -webkit-tap-highlight-color: transparent;
  }

  a {
    margin: 0 0 0 0.25em;
    cursor: pointer;
  }
}
