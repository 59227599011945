@import '../base/variables';

.page-container {
  max-width: $containerPageMaxWidth;
  width: 100%;
  margin: 0 auto;
  padding-left: $containerPagePaddingMobile;
  padding-right: $containerPagePaddingMobile;
  box-sizing: border-box;

  &.with-vertical {
    padding-top: $containerPagePaddingMobile;
    padding-bottom: $containerPagePaddingMobile;
  }

  &.small {
    max-width: 50.625rem; //46.875rem;
  }

  &.medium {
    max-width: 62rem;
  }
}

ui-container {
  position: relative;
  flex: 1;
}

ui-container.full-height {
  top: -3.5rem;
  background: $white;
  z-index: 2;
  width: 100%;
}

/* -------------------
        Container
-------------------- */

div.ui-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding: 1.5rem;
  border-top-left-radius: $containerBorderRadius;
  border-top-right-radius: $containerBorderRadius;
  box-shadow: 0 -5px 12px -1px rgba(0, 0, 0, 0.15);

  @media #{$fullScreen} {
    padding-left: $containerPagePaddingFullscreen;
    padding-right: $containerPagePaddingFullscreen;
  }

  @media #{$largeScreen} {
    padding-left: $containerPagePaddingLargeScreen;
    padding-right: $containerPagePaddingLargeScreen;
  }

  @media #{$tablet} {
    padding-left: $containerPagePaddingTablet;
    padding-right: $containerPagePaddingTablet;
  }
}

div.ui-container div.container-content {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  text-align: center;
}

div.ui-container div.container-content.with-header {
  min-height: calc(100% - 3.5rem);
  margin-top: 3.5rem;
}

/* -------------------
   Container helper
       classes
-------------------- */
div.ui-container.chat {
  margin-bottom: 3.3rem;
  overflow-x: hidden;
}

div.ui-container.transparent {
  background: transparent !important;
}

div.ui-container.compact {
  padding: 1.5rem 0 $containerVerticalPadding 0 !important;
}

div.ui-container.no-bottom-padding {
  padding-bottom: 0 !important;
}

div.ui-container.no-top-padding {
  padding-top: 0 !important;
}

div.ui-container.borderless {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  box-shadow: none;
}

div.ui-container.left div.container-content {
  text-align: left;
}

div.ui-container.right div.container-content {
  text-align: right;
}

div.project-details-wrapper div[class*='ui-container']:not(.h-scroll-container) {
  min-height: calc(100vh - #{$applicationHeaderHeight} - #{$projectDetailsOverviewHeight});

  div[class*='segment'] {
    min-height: 100%;
  }
}

div.ui-container.with-navigation {
  padding-bottom: 5rem !important;
}

div.ui-container.gold-background {
  background-color: $secondary6;
}

div.ui-container div.row.left.typing.indicator img {
  width: 4rem;
  height: auto;
}

div.ui-container.stretch-container div.container-content {
  height: 100%;
}

div.container {
  border-radius: 1.25rem 1.25rem 0 0;
  padding: 4.25rem 0 6.75rem; // 3.75rem 2rem 5rem;
  background: $white;
  position: relative;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;

  &.green {
    background: $light-green;
  }

  &.image {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding: 12rem 0 2rem;

    @media #{$mobile} {
      padding: 16.5rem 0 2rem;
    }
  }

  &.no-bottom-padding {
    padding-bottom: 0;
  }

  &.border-radius {
    border-radius: 1.25rem;
  }

  @media #{$mobile} {
    padding: 3rem 0 5rem;
  }
}

div.container.submerged {
  margin-top: -2.5rem;
}

div.container .title {
  font-size: 1.675rem;
}

div.container .page-title {
  // max-width: 50%;
  // text-align: left;
  // padding: 2rem 0;
  // margin: 0;
  // padding: 3rem 1.5rem 7rem;
  padding: 1rem 1.5rem 5.5rem;

  h1 {
    font-size: 2.5rem;
  }

  & > h1,
  & > h2,
  & > p {
    color: $white;
  }

  &.left {
    text-align: left;

    h1,
    p {
      color: $white;
      max-width: 33rem;
    }
  }
}
