@import '../base/variables';

.ui-get-wakanda-app {
  border: 1px dotted $secondary2;
  padding: 1.438rem 2.125rem;
  border-radius: 1rem;
  display: block;
  position: relative;
  margin-top: 1.563rem;

  h2 {
    margin-bottom: 0.813rem;
  }

  .trophy-block {
    margin-bottom: 1.375rem;
    display: flex;

    i.icon-trophy {
      width: 1.375rem;
      margin-right: 0.813rem;
    }

    p {
      width: 100%;
      flex: 1;
      text-align: left;
    }
  }

  .app-icons {
    .download-badge {
      width: 5.063rem;
      height: 1.5rem;
      display: inline-block;

      &.app-store {
        margin-right: 0.25rem;
      }
    }
  }
}
