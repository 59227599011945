@import '../base/variables';

div.ui-hamburger {
  cursor: pointer;
  width: 1.2rem;
}

div.ui-hamburger::after,
div.ui-hamburger::before,
div.ui-hamburger div {
  background-color: $white;
  border-radius: 3px;
  content: '';
  display: block;
  height: 3px;
  margin: 3px 0;
  transition: all 350ms ease-in-out;
}

div.ui-hamburger.dark,
div.ui-hamburger.dark.open {
  &::after,
  &::before,
  div {
    background-color: $black;
  }
}

div.ui-hamburger.open::after,
div.ui-hamburger.open::before,
div.ui-hamburger.open div {
  @media #{$tablet}, #{$largeScreen}, #{$fullScreen} {
    background-color: $white;
  }

  background-color: $black;
}

div.ui-hamburger.open::before {
  transform: translateY(4px) rotate(135deg);
}

div.ui-hamburger.open::after {
  transform: translateY(-8px) rotate(-135deg);
}

div.ui-hamburger.open div {
  transform: scale(0);
}
