@import '../base/variables';

ui-slider-simple {
  .slider-simple {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    background: $secondary2;
    outline: none;
  }

  .slider-simple::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: $primary1;
    cursor: pointer;
    border-radius: 50%;
  }

  .slider-simple::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background: $primary1;
    cursor: pointer;
    border-radius: 50%;
  }
}
