@import '../base/variables';

/* -------------------
        Days
-------------------- */
div.ui.calendar.days.names-of-the-days {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-transform: uppercase;
}

div.ui.calendar.days.names-of-the-days > span.day.column {
  width: 14.285%;
  text-align: center;
}

div.ui.calendar.days.names-of-the-days > span.day.column > div.day {
  height: 2.188rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.ui.calendar.days.names-of-the-days > span.day.column div.day.selected {
  background: $secondary1;

  p.small.light {
    font: $h4;
    color: $primary3;
  }
}

div.ui.calendar.days.names-of-the-days > span.day.column div.day.selected.first {
  border-top-left-radius: 2em;
  border-bottom-left-radius: 2em;
}

div.ui.calendar.days.names-of-the-days > span.day.column div.day.selected.last {
  border-top-right-radius: 2em;
  border-bottom-right-radius: 2em;
}

/* -------------------
        Picker
-------------------- */
div.ui.calendar.picker {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto 3em;
}

div.ui.calendar.picker p.small.light {
  margin: 1em 0;
}

div.ui.calendar.picker div.date {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

div.ui.calendar.picker div.date ui-icon {
  margin: 0 1.625rem;
}

/* -------------------
    Monthly Picker
-------------------- */
ui-calendar-monthly-picker {
  display: block;
  width: 100%;
  position: relative;

  .fade {
    width: 20%;
    height: 100%;
    position: absolute;
    display: block;
    pointer-events: none;
    z-index: 1;
    top: 0;
    bottom: 0;

    &.start {
      left: 0;

      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0.5+0,0+100 */
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0.65) 0%, rgba(255, 255, 255, 0) 100%); /* FF3.6-15 */
      background:
        -webkit-linear-gradient(
          left,
          rgba(255, 255, 255, 0.65) 0%,
          rgba(255, 255, 255, 0) 100%
        ); /* Chrome10-25,Safari5.1-6 */

      background:
        linear-gradient(
          to right,
          rgba(255, 255, 255, 0.65) 0%,
          rgba(255, 255, 255, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

      filter: progid:dximagetransform.microsoft.gradient(startColorstr='#80ffffff', endColorstr='#00ffffff', GradientType=1); /* IE6-9 */
    }

    &.end {
      right: 0;

      /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,0.65+100 */
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.65) 100%); /* FF3.6-15 */
      background:
        -webkit-linear-gradient(
          left,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.65) 100%
        ); /* Chrome10-25,Safari5.1-6 */

      background:
        linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.65) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

      filter: progid:dximagetransform.microsoft.gradient(startColorstr='#00ffffff', endColorstr='#80ffffff', GradientType=1); /* IE6-9 */
    }
  }
}

div.ui.calendar-monthly-picker {
  width: 100%;
  height: 3rem;
  overflow-x: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.ui.calendar-monthly-picker > div.wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  position: absolute;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
}

div.ui.calendar-monthly-picker > div.wrapper span.item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;
  height: 2.188rem;
}

div.ui.calendar-monthly-picker > div.wrapper span.item.selected {
  height: 2.188rem;
  border: 1px solid $text2;
  border-radius: 1.125rem;
  box-sizing: border-box;

  p {
    color: $text2;
  }
}
