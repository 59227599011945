@import '../base/variables';

ui-tab {
  display: block;
}

/* -------------------
        Container
-------------------- */

div.ui-tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
}

div.ui-tab div.tab-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: $white;
  padding: 1.5rem 0;
  position: relative;
  min-height: 100%;
  text-align: center;
  box-sizing: border-box;
}

/* -------------------
   Container helper
       classes
-------------------- */

div.ui-tab.compact div.tab-wrapper {
  padding: 1.5rem 0 0 0 !important;
}

div.ui-tab.no-bottom-padding div.tab-wrapper {
  padding-bottom: 0 !important;
}

div.ui-tab.no-top-padding div.tab-wrapper {
  padding-top: 0 !important;
}

div.ui-tab.borderless div.tab-wrapper {
  box-shadow: none;
}

div.ui-tab.left div.tab-wrapper {
  text-align: left;
}

div.ui-tab.right div.tab-wrapper {
  text-align: right;
}

/* -------------------
   Header helper
       classes
-------------------- */

div.ui-tab div.tab-header {
  width: 100%;
  z-index: 5;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid $secondary3;
}

div.ui-tab span.tab-header-item {
  cursor: pointer;
  margin-top: 0.5rem;
  min-width: 10rem;
  padding: 0.625rem;
  margin-right: 0.25rem;
  border-bottom: 3px solid $secondary2;

  h3.required {
    padding: 0 0 0 0.25rem;
    color: $text3 !important;
  }
}

div.ui-tab span.tab-header-item.small {
  min-width: 6.125rem;
}

div.ui-tab span.tab-header-item.tab-selected {
  border-bottom: 3px solid $primary1;

  h3 {
    color: $primary1;
  }
}

div.tabs {
  display: flex;
  justify-content: center;
  overflow-x: auto;

  @media #{$mobile} {
    justify-content: flex-start;
  }

  .tab-item {
    position: relative;
    border-bottom: 1px solid $secondary1;
    padding: 0.5rem 1rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    font: $bodyCopy4;
    color: $text1;
    white-space: nowrap;
    cursor: pointer;
  }

  .tab-item.active {
    color: $secondary5;
  }

  &:hover {
    color: $secondary5;
  }

  .tab-item.active::before {
    content: '';
    background-color: $primary1;
    height: 2px;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
  }
}
