/* -------------------
 General Theme colors
-------------------- */
$primary1: #009639;
$primary2: #009639;
$primary3: #fff;
$primary4: #fff;
$primary5: none;

$secondary1: #eaeaea;
$secondary2: #ccc;
$secondary3: #eaeaea;
$secondary4: #ce98fb;
$secondary5: #000;
$secondary6: #fcffdd;

$tertiary1: #000001;
$tertiary2: #303030;

$text1: #666;
$text2: #0059ff;
$text3: #db504a;
$text4: #009532;
$text5: none;
$text6: #f5b157;
$text7: #b5b5b5;

$black: #000;
$white: #fff;
$red: #f11643;
$light-gray: #f9f8f8;
$light-green: #ecf9f2;

$visibleModalLayer: rgba(0, 0, 0, 0.5);

/* -------------------
Global Fonts Settings
-------------------- */
$fontSize: 16px; // The size of page text
$textColor: $black;

/* -------------------
 Global Page Settings
-------------------- */
$pageBackground: $primary3;
$pageContentPaddingOnSide: 1.25em;
$pageContentPadding: 0 $pageContentPaddingOnSide 0 $pageContentPaddingOnSide;
$pageFont: 'Nunito Sans Regular';

/* -------------------
      Typography
-------------------- */
$h1: normal normal normal 1.875rem 'Nunito Sans Bold';
$h2: normal normal normal 1.125rem 'Nunito Sans Bold';
$h3: normal normal normal 0.875rem 'Nunito Sans Bold';
$h4: normal normal normal 0.75rem 'Nunito Sans Bold';
$hyperlink: normal normal normal 0.875rem 'Nunito Sans Bold';
$smallerHyperlink: normal normal normal 0.75rem 'Nunito Sans Bold';
$h1Color: $black;
$h2Color: $black;
$h3Color: $black;
$h4Color: $black;
$hyperlinkColor: $text2;
$smallerHyperlinkColor: $text2;

$bodyCopy1: normal normal normal 0.875rem 'Nunito Sans Regular';
$bodyCopy2: normal normal normal 0.75rem 'Nunito Sans Regular';
$bodyCopy3: normal normal normal 0.75rem 'Nunito Sans Regular';
$bodyCopy4: normal normal normal 0.875rem 'Nunito Sans Bold';
$bodyCopy5: normal normal normal 0.75rem 'Nunito Sans Bold';
$bodyCopy6: normal normal normal 0.75rem 'Nunito Sans Bold';
$subtext: normal normal normal 0.625rem 'Nunito Sans Italic';
$bodyCopy1Color: $black;
$bodyCopy2Color: $black;
$bodyCopy3Color: $text1;
$bodyCopy4Color: $black;
$bodyCopy5Color: $text4;
$bodyCopy6Color: $text1;
$subtextColor: $text1;

$textsMargin: 0;
$headersMargin: 0;

/* -------------------
Application Containers
-------------------- */
$applicationHeaderHeight: 4rem;
$applicationNavigationHeight: 3.375rem;
$projectDetailsOverviewHeight: 4em;

/* -------------------
      Container
-------------------- */
$containerMaxWidth: 100vw;
$containerBorderRadius: 1.25rem;
$containerDefaultBackgroundColor: transparent;
$containerPadding: 0;
$containerHorizontalPadding: 1.25rem;
$containerVerticalPadding: 1.5rem;
$containerContentPadding: $containerVerticalPadding $containerHorizontalPadding;

$containerPageMaxWidth: 90rem;
$containerPagePaddingMobile: 1.5rem;
$containerPagePaddingFullscreen: 10%;
$containerPagePaddingLargeScreen: 2.5%;
$containerPagePaddingTablet: 2.5%;

/* -------------------
   Responsibility
-------------------- */
$mobile: 'only screen and (max-width: 760px)';
$tablet: 'only screen and (min-width: 760px) and (max-width: 1024px)';
$largeScreen: 'only screen and (min-width: 1025px) and (max-width: 1366px)';
$fullScreen: 'only screen and (min-width: 1367px)';

/* -------------------
    animation keys
-------------------- */
$fadeInDownAnimationKey: fade-in-down;
$slideInUpAnimationKey: slide-in-up;
$fadeInAnimationKey: fade-in;
$pushDownAnimationKey: push-down;
$slideUpAnimationKey: slide-up;

/* -------------------
       animations
-------------------- */
$slideUpAnimation: $slideUpAnimationKey 0.2s ease-in !important;
$pushDownAnimation: $pushDownAnimationKey 0.3s ease-in !important;
$fadeInAnimation: $fadeInAnimationKey 0.3s ease-out !important;
$slideInUpAnimation: $slideInUpAnimationKey 0.3s ease-out !important;

/* -------------------
      Others
-------------------- */
$carouselButtonBackground: #eaeaea;
$dividerBorderColor: $secondary2;
$discountFlagWidth: 1.875rem;
$buttonFont: $bodyCopy2;
$buttonDefaultBorder: 1px solid $black;
$buttonDefaultBackground: transparent;
$buttonDefaultColor: $black;
$buttonSelectedBorder: 1px solid $primary1;
$buttonSelectedBackground: $primary1;
$buttonSelectedColor: $white;
$buttonDisabledBackground: #ccc;
$buttonDisabledColor: $white;
$buttonDisabledBorder: 1px solid #ccc;
$buttonCTAFont: $bodyCopy1;
$buttonCTABackground: $primary1;
