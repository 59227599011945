@import '../base/variables';

.small-task-svg-spinner {
  width: 1.25rem;
  height: 1.25rem;
  background: $secondary3;
}

.small-task-image-spinner,
.mini-task-spinner,
.small-agent-task-spinner,
.large-task-spinner,
.small-task-spinner {
  border-radius: 50%;
  background-color: transparent;
  animation: 1.5s smallTaskSpinnerAnimation linear infinite;
  z-index: 2;

  &.padded {
    margin: 0 0 0.2rem auto;
  }
}

.mini-task-spinner {
  width: 1rem;
  height: 1rem;
  border: 2.5px solid $text4;
  border-top: 2.5px solid $black;
}

.small-task-image-spinner {
  width: 75%;
  height: 75%;
  max-width: 1.3rem;
  max-height: 1.3rem;
  border: 2.5px solid $secondary3;
  border-top: 2.5px solid $black;
}

.small-task-spinner {
  width: 1.3rem;
  height: 1.3rem;
  border: 2.5px solid $text4;
  border-top: 2.5px solid $black;
}

.large-task-spinner {
  width: 5rem;
  height: 5rem;
  border: 5px solid $text4;
  border-top: 5px solid $black;
  margin: 0;
}

.small-agent-task-spinner {
  width: 1.3rem;
  height: 1.3rem;
  border: 2px solid $secondary3;
  border-top: 2px solid $black;

  &.chat {
    width: 1.2rem;
    height: 1.2rem;
    border-top: 2px solid $text5;
  }
}

@-webkit-keyframes smallTaskSpinnerAnimation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes smallTaskSpinnerAnimation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

div.ui-camera-loader-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $visibleModalLayer;
}

div.ui-camera-loader-wrapper p {
  width: 10rem;
  text-align: center;
  color: $white;
}

div.ui-camera-loader-wrapper div.dot-bricks-spinner {
  position: relative;
  top: 8px;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background: #16dba6;
  color: #16dba6;
  box-shadow: 9991px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6, 10007px 0 0 0 #16dba6;
  animation: dotBricksSpinner 2s infinite ease;
}

@keyframes dotBricksSpinner {
  0% {
    box-shadow: 9991px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6, 10007px 0 0 0 #16dba6;
  }

  8.333% {
    box-shadow: 10007px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6, 10007px 0 0 0 #16dba6;
  }

  16.667% {
    box-shadow: 10007px -16px 0 0 #16dba6, 9991px -16px 0 0 #16dba6, 10007px 0 0 0 #16dba6;
  }

  25% {
    box-shadow: 10007px -16px 0 0 #16dba6, 9991px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6;
  }

  33.333% {
    box-shadow: 10007px 0 0 0 #16dba6, 9991px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6;
  }

  41.667% {
    box-shadow: 10007px 0 0 0 #16dba6, 10007px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6;
  }

  50% {
    box-shadow: 10007px 0 0 0 #16dba6, 10007px -16px 0 0 #16dba6, 9991px -16px 0 0 #16dba6;
  }

  58.333% {
    box-shadow: 9991px 0 0 0 #16dba6, 10007px -16px 0 0 #16dba6, 9991px -16px 0 0 #16dba6;
  }

  66.666% {
    box-shadow: 9991px 0 0 0 #16dba6, 10007px 0 0 0 #16dba6, 9991px -16px 0 0 #16dba6;
  }

  75% {
    box-shadow: 9991px 0 0 0 #16dba6, 10007px 0 0 0 #16dba6, 10007px -16px 0 0 #16dba6;
  }

  83.333% {
    box-shadow: 9991px -16px 0 0 #16dba6, 10007px 0 0 0 #16dba6, 10007px -16px 0 0 #16dba6;
  }

  91.667% {
    box-shadow: 9991px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6, 10007px -16px 0 0 #16dba6;
  }

  100% {
    box-shadow: 9991px -16px 0 0 #16dba6, 9991px 0 0 0 #16dba6, 10007px 0 0 0 #16dba6;
  }
}

/* -------------------
       SPINNER
-------------------- */
.loading-spiner-v3 {
  display: block;
  position: relative;
  width: 7.813rem;
  height: 7.813rem;
  border-radius: 50%;
  border: 4px solid transparent;
  border-top: 4px solid $secondary4;
  margin: -7rem 0 0 0;
  animation: loadingSpinnerV3Animation 2s linear infinite;
}

.loading-spiner-v3::before,
.loading-spiner-v3::after {
  content: '';
  position: absolute;
  border-radius: 50%;
  border: 4px solid transparent;
}

.loading-spiner-v3::before {
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-top-color: $text4;
  -webkit-animation: loadingspinnerv3animation 3s linear infinite;
  animation: loadingSpinnerV3Animation 3.5s linear infinite;
}

.loading-spiner-v3::after {
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-top-color: $tertiary1;
  -webkit-animation: loadingspinnerv3animation 1.5s linear infinite;
  animation: loadingSpinnerV3Animation 1.75s linear infinite;
}

@-webkit-keyframes loadingSpinnerV3Animation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loadingSpinnerV3Animation {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* -------------------
  AGENT CHAT SPINNER
-------------------- */
.agent-spinner {
  width: 100px;
  height: 102px;
  border-radius: 100%;
  position: absolute;
  top: 36%;
  left: calc(50% - 50px);
  animation: spin 5s infinite linear;
}

.circle {
  width: 100%;
  height: 100%;
  position: absolute;
}

.circle .inner {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 5px solid rgba(52, 195, 208, 0.7);
  border-right: none;
  border-top: none;
  box-shadow: inset 0 0 10px rgba(52, 195, 208, 0.15);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.circle:nth-of-type(0) {
  transform: rotate(0deg);
}

.circle:nth-of-type(0) .inner {
  animation: spin 2s infinite linear;
}

.circle:nth-of-type(1) {
  transform: rotate(70deg);
}

.circle:nth-of-type(1) .inner {
  animation: spin 2s infinite linear;
}

.circle:nth-of-type(2) {
  transform: rotate(140deg);
}

.circle:nth-of-type(2) .inner {
  animation: spin 2s infinite linear;
}
