@import '../base/variables';

[slider] {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

[slider] > div {
  flex: 1;
  height: 0.313rem;
  position: relative;
}

[slider] > div > [inverse-left] {
  height: 0.313rem;
  position: absolute;
  left: 0;
  border-radius: 10px;
  background-color: #ebebeb;
}

[slider] > div > [inverse-right] {
  height: 0.313rem;
  position: absolute;
  right: 0;
  border-radius: 10px;
  background-color: #ebebeb;
}

[slider] > div > [range] {
  height: 0.313rem;
  position: absolute;
  left: 0;
  border-radius: 14px;
  background-color: #60992d;
}

[slider] > div > [thumb] {
  height: 1.2rem;
  width: 1.2rem;
  cursor: pointer;
  z-index: 2;
  position: absolute;
  top: -0.4rem;
  text-align: left;
  margin-left: -0.6rem;
  background-color: #60992d;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
}

[slider] > input[type='range'] {
  position: absolute;
  left: 0;
  right: 0;
  height: 0.313rem;
  width: 100%;
  pointer-events: none;
  z-index: 3;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-appearance: none;
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
  -moz-opacity: 0;
}

div[slider] > input[type='range']::-ms-track {
  -webkit-appearance: none;
  background: transparent;
  color: transparent;
}

div[slider] > input[type='range']::-moz-range-track {
  -moz-appearance: none;
  background: transparent;
  color: transparent;
}

div[slider] > input[type='range']:focus::-webkit-slider-runnable-track {
  background: transparent;
  border: transparent;
}

div[slider] > input[type='range']:focus {
  outline: none;
}

div[slider] > input[type='range']::-ms-thumb {
  pointer-events: all;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0;
  border: 0 none;
  background: #60992d;
}

div[slider] > input[type='range']::-moz-range-thumb {
  pointer-events: all;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0;
  border: 0 none;
  background: #60992d;
}

div[slider] > input[type='range']::-webkit-slider-thumb {
  pointer-events: all;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 0;
  border: 0 none;
  background: #60992d;
  -webkit-appearance: none;
}

div[slider] > input[type='range']::-ms-fill-lower {
  background: transparent;
  border: 0 none;
}

div[slider] > input[type='range']::-ms-fill-upper {
  background: transparent;
  border: 0 none;
}

div[slider] > input[type='range']::-ms-tooltip {
  display: none;
}

[slider] > div > [sign] {
  z-index: 3;
  width: 1.75rem;
  height: 1.75rem;
  position: absolute;
  top: -39px;
  margin-left: -0.8rem;
  background-color: #60992d;
  color: #ebebeb;
  border-radius: 28px;
  align-items: center;
  justify-content: center;
  text-align: center;
  -webkit-border-radius: 28px;
  -webkit-justify-content: center;
}

[slider] > div > [sign]::after {
  position: absolute;
  content: '';
  left: 0;
  border-radius: 16px;
  top: 19px;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-top: 16px solid #60992d;
}

[slider] > div > [sign] > span {
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
}

div.ui-slider-labels {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
