@import '../base/variables';

/* -------------------
Global Expand Settings
-------------------- */

div.ui-expand div.title {
  cursor: pointer;
  display: flex;
  height: 2.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.75rem;
}

div.ui-expand.grey-background {
  background-color: $secondary3;
}

div.ui-expand div.title b {
  color: $text1;
  user-select: none;
}

div.ui-expand div.content.collapsed {
  max-height: 0;
  transition: max-height 0.25s ease-out;
  overflow: hidden;
}

div.ui-expand div.content.expanded {
  max-height: inherit;
  overflow: hidden;
  transition: max-height 0.25s ease-in;
}
